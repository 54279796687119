import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MODAL_TYPE, ModalContext } from "../context/modals";
import { UserContext } from "../context/users";
import { Container, Navbar, Button } from "react-bootstrap";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { AiFillCar } from "react-icons/ai";

const Header = () => {
  const navigate = useNavigate();
  const { openModal } = useContext(ModalContext);
  const { currentUser } = useContext(UserContext);

  const { authStatus, user, signOut } = useAuthenticator();

  const navigateToDashboard = () => {
    if (currentUser) {
      navigate(`/dashboard/`);
    }
  };

  const onClickSignOut = async () => {
    try {
      await signOut();
      window.alert("Successfully signed out!");
      navigate("/");
    } catch (err) {
      console.error("Error signing out:", err);
    }
  };

  return (
    <Navbar>
      <Container>
        <Navbar.Brand onClick={() => navigate("/")}>
          <AiFillCar
            width="30"
            height="30"
            className="d-inline-block align-middle"
          />{" "}
          X-MATCH
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          {authStatus !== "authenticated" ? (
            <Navbar.Text>
              <Button
                variant="outline-secondary"
                onClick={() => openModal(MODAL_TYPE.SIGNIN)}
              >
                ログイン
              </Button>{" "}
            </Navbar.Text>
          ) : (
            <>
              <Navbar.Text>
                <a href="#login">{user && user?.attributes?.email}</a>
                としてサインインしました.
              </Navbar.Text>
              {currentUser && currentUser?.role === "RENTEE" ? (
                <Button
                  variant="outline-secondary"
                  style={{margin: "0 3px"}}
                  onClick={() => openModal(MODAL_TYPE.CREATE_CAR)}
                >
                  車種
                </Button>
              ) : (
                <></>
              )}
              <Button
                variant="outline-secondary"
                style={{margin: "0 3px"}}
                onClick={() => navigateToDashboard()}
              >
                ダッシュボード
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => onClickSignOut()}
              >
                ログアウト
              </Button>
            </>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
