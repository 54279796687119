import React, { useState, useEffect, createContext } from "react";
import { API } from "aws-amplify";
import { listUsers } from "../graphql-freezed/queries";
import { useAuthenticator } from "@aws-amplify/ui-react";

const UserContext = createContext({
  currentUser: {},
  setUser: () => {},
  removeUser: () => {},
});

const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});

  const { user, authStatus } = useAuthenticator();

  useEffect(() => {
    if (authStatus === "authenticated") {
      setUser();
    }
    // eslint-disable-next-line
  }, [authStatus]);

  const setUser = async () => {
    try {
      // Switch authMode to API_KEY for public access
      const variables = {
        filter: {
          email: {
            eq: user?.attributes?.email
          }
        },
      };
      const { data } = await API.graphql({
        query: listUsers,
        variables: variables,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const loggedinuser = data.listUsers.items
        ? data?.listUsers?.items[0]
        : {};

      setCurrentUser(loggedinuser);
    } catch (err) {
      console.log(err);
    }
  };

  const removeUser = async () => {
    setCurrentUser({});
  };

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setUser,
        removeUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
