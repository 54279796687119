import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const Hero = () => {
  return (
    <section className="hero">
      <Button size="lg" style={{marginTop: "15%"}}>
        <Link className="btn" to="/cars">
          ＞
        </Link>
      </Button>
    </section>
  );
};

export default Hero;
