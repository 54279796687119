import React, { useContext } from "react";
import { CarContext } from "../context/cars";
import { MODAL_TYPE, ModalContext } from "../context/modals";
import { Card, Button, Row, Col } from "react-bootstrap";
import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import { UserContext } from "../context/users";

const Cars = () => {
  const { cars, selectCar } = useContext(CarContext);
  const { openModal } = useContext(ModalContext);
  const { currentUser } = useContext(UserContext);

  if (!cars.length) {
    return <h3>車はありません。</h3>;
  }

  return (
    <Row xs={1} md={2} className="justify-content-md-center p-5 panel">
      {cars.map((car) => (
        <Col xs lg="3" key={car.id} style={{ marginBottom: "10px" }}>
          <Card>
            {currentUser?.id === car.user_id ? (
              <Card.Header className="text-end">
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    selectCar(car);
                    openModal(MODAL_TYPE.EDIT_CAR);
                  }}
                >
                  <BsFillPencilFill />
                </Button>{" "}
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    selectCar(car);
                    openModal(MODAL_TYPE.DELETE_CAR);
                  }}
                >
                  <BsFillTrashFill />
                </Button>
              </Card.Header>
            ) : (
              <div></div>
            )}
            <Card.Img
              variant="top"
              src={car.image}
              style={{ maxHeight: "200px", alignSelf: "center", width: "auto"}}
            />
            <Card.Body>
              <Card.Title>
                {car.make} - {car.model}
              </Card.Title>
              <Card.Text>
                年式: {car.year}年 - {car.length} mm
                <br />
                {car.price?.toLocaleString()}円 
                {/* - {car.capacity}人 */}
              </Card.Text>
              {currentUser && currentUser?.role === "RENTER" ? (
                <Button
                  className="form-control submit-buttom"
                  onClick={() => {
                    selectCar(car);
                    openModal(MODAL_TYPE.CAR_DETAIL);
                  }}
                  disabled={!car.availability}
                >
                  詳細
                </Button>
              ) : (
                <></>
              )}
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Cars;
