import React, { useContext } from "react";
import { CarContext } from "../context/cars";
import { MODAL_TYPE, ModalContext } from "../context/modals";
import { Modal, Button, ListGroup } from "react-bootstrap";

const DeleteCar = () => {
  const { removeCar, car: currentCar } = useContext(CarContext);
  const { modalType, closeModal, openModal } = useContext(ModalContext);

  if (!currentCar) {
    return <h3>Loading...</h3>;
  }

  const {
    image: url,
    id,
    make,
    model,
    year,
    // capacity,
    length,
    price,
    _version,
  } = currentCar;

  return (
    <Modal
      style={{ backgroundColor: "transparent" }}
      show={modalType === MODAL_TYPE.DELETE_CAR}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>車を削除しますか？</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="detail-image">
          <img src={url} alt="10x Rule" className="w-50" />
        </div>
        <div className="detail-description" style={{ textAlign: "center" }}>
          <h2>{make}</h2>
          <p>
            {model} - 年式: {year}年{" "}
          </p>
          <ListGroup className="list-group-flush">
            <ListGroup.Item>値段 - {price?.toLocaleString()}円</ListGroup.Item>
            {/* <ListGroup.Item>容積 - {capacity}人</ListGroup.Item> */}
            <ListGroup.Item>長さ - {length} mm</ListGroup.Item>
          </ListGroup>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="submit-buttom"
          onClick={() => {
            removeCar(id, _version);
            openModal(MODAL_TYPE.NONE);
          }}
        >
          デリート
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCar;
