/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      last_name
      first_name
      kana_last
      kana_first
      gender
      date_of_birth
      postal_code
      prefecture
      city
      town
      street
      building
      home_number
      mobile_number
      role
      cars {
        nextToken
        startedAt
        __typename
      }
      bookings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        last_name
        first_name
        kana_last
        kana_first
        gender
        date_of_birth
        postal_code
        prefecture
        city
        town
        street
        building
        home_number
        mobile_number
        role
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        last_name
        first_name
        kana_last
        kana_first
        gender
        date_of_birth
        postal_code
        prefecture
        city
        town
        street
        building
        home_number
        mobile_number
        role
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCar = /* GraphQL */ `
  query GetCar($id: ID!) {
    getCar(id: $id) {
      id
      make
      model
      year
      price
      capacity
      image
      length
      featured
      availability
      user_id
      category
      bookings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCars = /* GraphQL */ `
  query ListCars(
    $filter: ModelCarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        make
        model
        year
        price
        capacity
        image
        length
        featured
        availability
        user_id
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCars = /* GraphQL */ `
  query SyncCars(
    $filter: ModelCarFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCars(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        make
        model
        year
        price
        capacity
        image
        length
        featured
        availability
        user_id
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const carsByUser_idAndId = /* GraphQL */ `
  query CarsByUser_idAndId(
    $user_id: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    carsByUser_idAndId(
      user_id: $user_id
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        make
        model
        year
        price
        capacity
        image
        length
        featured
        availability
        user_id
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      startDate
      endDate
      date
      total
      car {
        id
        make
        model
        year
        price
        capacity
        image
        length
        featured
        availability
        user_id
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        email
        last_name
        first_name
        kana_last
        kana_first
        gender
        date_of_birth
        postal_code
        prefecture
        city
        town
        street
        building
        home_number
        mobile_number
        role
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userBookingsId
      carBookingsId
      customer
      __typename
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startDate
        endDate
        date
        total
        car {
          id
          category
          image
          make
          model
        }
        user {
          email
          id
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userBookingsId
        carBookingsId
        customer
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBookings = /* GraphQL */ `
  query SyncBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        startDate
        endDate
        date
        total
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userBookingsId
        carBookingsId
        customer
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
