/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processBooking = /* GraphQL */ `
  mutation ProcessBooking($input: ProcessBookingInput!) {
    processBooking(input: $input)
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      last_name
      first_name
      kana_last
      kana_first
      gender
      date_of_birth
      postal_code
      prefecture
      city
      town
      street
      building
      home_number
      mobile_number
      role
      cars {
        nextToken
        startedAt
        __typename
      }
      bookings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      last_name
      first_name
      kana_last
      kana_first
      gender
      date_of_birth
      postal_code
      prefecture
      city
      town
      street
      building
      home_number
      mobile_number
      role
      cars {
        nextToken
        startedAt
        __typename
      }
      bookings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      last_name
      first_name
      kana_last
      kana_first
      gender
      date_of_birth
      postal_code
      prefecture
      city
      town
      street
      building
      home_number
      mobile_number
      role
      cars {
        nextToken
        startedAt
        __typename
      }
      bookings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCar = /* GraphQL */ `
  mutation CreateCar(
    $input: CreateCarInput!
    $condition: ModelCarConditionInput
  ) {
    createCar(input: $input, condition: $condition) {
      id
      make
      model
      year
      price
      capacity
      image
      length
      featured
      availability
      user_id
      category
      bookings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCar = /* GraphQL */ `
  mutation UpdateCar(
    $input: UpdateCarInput!
    $condition: ModelCarConditionInput
  ) {
    updateCar(input: $input, condition: $condition) {
      id
      make
      model
      year
      price
      capacity
      image
      length
      featured
      availability
      user_id
      category
      bookings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCar = /* GraphQL */ `
  mutation DeleteCar(
    $input: DeleteCarInput!
    $condition: ModelCarConditionInput
  ) {
    deleteCar(input: $input, condition: $condition) {
      id
      make
      model
      year
      price
      capacity
      image
      length
      featured
      availability
      user_id
      category
      bookings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      startDate
      endDate
      date
      total
      car {
        id
        make
        model
        year
        price
        capacity
        image
        length
        featured
        availability
        user_id
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        email
        last_name
        first_name
        kana_last
        kana_first
        gender
        date_of_birth
        postal_code
        prefecture
        city
        town
        street
        building
        home_number
        mobile_number
        role
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userBookingsId
      carBookingsId
      customer
      __typename
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      startDate
      endDate
      date
      total
      car {
        id
        make
        model
        year
        price
        capacity
        image
        length
        featured
        availability
        user_id
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        email
        last_name
        first_name
        kana_last
        kana_first
        gender
        date_of_birth
        postal_code
        prefecture
        city
        town
        street
        building
        home_number
        mobile_number
        role
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userBookingsId
      carBookingsId
      customer
      __typename
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      startDate
      endDate
      date
      total
      car {
        id
        make
        model
        year
        price
        capacity
        image
        length
        featured
        availability
        user_id
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        email
        last_name
        first_name
        kana_last
        kana_first
        gender
        date_of_birth
        postal_code
        prefecture
        city
        town
        street
        building
        home_number
        mobile_number
        role
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userBookingsId
      carBookingsId
      customer
      __typename
    }
  }
`;
