import { Auth } from "aws-amplify";
import React, { useContext } from "react";
import {
  Authenticator,
  useAuthenticator,
  SelectField,
} from "@aws-amplify/ui-react";

import { Modal } from "react-bootstrap";
import { MODAL_TYPE, ModalContext } from "../context/modals";

const Signin = () => {
  const { modalType, closeModal } = useContext(ModalContext);

  const services = {
    async handleSignUp(formData) {
      let { username, password, attributes } = formData;
      username = username.toLowerCase();
      attributes.email = attributes.email.toLowerCase();
      return Auth.signUp({
        username,
        password,
        attributes,
        autoSignIn: {
          enabled: true,
        },
      }).catch((error) => {
        console.error("Sign-up error:", error);
      });
    },
  };

  const signUpFields = {
    signUp: {
      email: {
        order: 1,
      },
      password: {
        order: 2,
      },
      confirm_password: {
        order: 3,
      },
      "custom:last_name": {
        placeholder: "Enter your Last Name here",
        isRequired: true,
        label: "Last Name",
        order: 4,
      },
      "custom:first_name": {
        placeholder: "Enter your First Name here",
        isRequired: true,
        label: "First Name",
        order: 5,
      },
      "custom:kana_last": {
        placeholder: "Enter your Kana Last here",
        isRequired: true,
        label: "Kana Last",
        order: 6,
      },
      "custom:kana_first": {
        placeholder: "Enter your Kana First here",
        isRequired: true,
        label: "Kana First",
        order: 7,
      },
      "custom:date_of_birth": {
        placeholder: "Enter your DOB here",
        isRequired: true,
        label: "Date of Birth",
        type: "date",
        order: 9,
      },
      "custom:postal_code": {
        placeholder: "Enter your Postal Code here",
        isRequired: true,
        label: "Postal Code",
        type: "number",
        order: 10,
      },
      "custom:prefecture": {
        placeholder: "Enter your Prefecture here",
        isRequired: true,
        label: "Prefecture",
        order: 11,
      },
      "custom:city": {
        placeholder: "Enter your City here",
        isRequired: true,
        label: "City",
        order: 12,
      },
      "custom:town": {
        placeholder: "Enter your Town here",
        isRequired: true,
        label: "Town",
        order: 13,
      },
      "custom:street": {
        placeholder: "Enter your Street here",
        isRequired: true,
        label: "Street",
        order: 14,
      },
      "custom:building": {
        placeholder: "Enter your Building here",
        isRequired: true,
        label: "Building",
        order: 15,
      },
      "custom:home_number": {
        placeholder: "Enter your Home Number here",
        isRequired: true,
        label: "Home Number",
        type: "tel",
        dialCode: "+81",
        order: 16,
      },
      "custom:mobile_number": {
        placeholder: "Enter your Mobile here",
        isRequired: true,
        label: "Mobile Number",
        type: "tel",
        dialCode: "+81",
        order: 17,
      },
    },
  };

  return (
    <Modal
      show={modalType === MODAL_TYPE.SIGNIN}
      onHide={closeModal}
      style={{ backgroundColor: "transparent" }}
    >
      <Authenticator
        // Customize `Authenticator.SignUp.FormFields`
        components={{
          SignUp: {
            FormFields() {
              const { validationErrors } = useAuthenticator();

              return (
                <>
                  {/* Re-use default `Authenticator.SignUp.FormFields` */}
                  <Authenticator.SignUp.FormFields />

                  {/* Append & require Terms & Conditions field to sign up  */}
                  <SelectField
                    errorMessage={validationErrors.gender}
                    hasError={!!validationErrors.gender}
                    label="Gender"
                    name="custom:gender"
                    key="custom:gender"
                  >
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                  </SelectField>

                  <SelectField
                    errorMessage={validationErrors.role}
                    hasError={!!validationErrors.role}
                    label="Role"
                    name="custom:role"
                    key="custom:role"
                  >
                    <option value="RENTER">借りる</option>
                    <option value="RENTEE">Rentee</option>
                  </SelectField>
                </>
              );
            },
          },
        }}
        formFields={signUpFields}
        services={services}
      ></Authenticator>
    </Modal>
  );
};

export default Signin;
