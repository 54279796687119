import React, { useContext } from "react";
import Hero from "../components/Hero";
import { CarContext } from "../context/cars";
import { MODAL_TYPE, ModalContext } from "../context/modals";
import { UserContext } from "../context/users";
import { Link } from "react-router-dom";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";

const Home = () => {
  const { featured, selectCar } = useContext(CarContext);
  const { openModal } = useContext(ModalContext);
  const { currentUser } = useContext(UserContext);

  if (Object.keys(featured).length === 0) {
    return <h3>最新情報はありません。</h3>;
  }

  const categoryTranslations = {
    EVENTCAR: "イベントカー",
    KITCHENCAR: "キッチンカー",
    CAMPINGCAR: "キャンピングカー",
  };

  return (
    <>
      <Container fluid="true">
        <Row>
          <Hero />
          <header className="featured-head">
            <h3>最新情報</h3>
          </header>
        </Row>
        {Object.entries(featured).map(([category, cars]) => (
          <div key={category} >
            <Row md={8} className="pt-5" style={{paddingLeft: "7rem"}}>
              <h4>{categoryTranslations[category]}</h4>
            </Row>
            <Container fluid="true" className="panel">
              <Row
                xs={1}
                md={2}
                className="justify-content-md-center p-5 panel"
              >
                {cars.map((car) => (
                  <Col xs lg="3" key={car.id}>
                    <Card>
                      {currentUser?.id === car.user_id ? (
                        <Card.Header className="text-end">
                          <Button
                            variant="outline-secondary"
                            onClick={() => {
                              selectCar(car);
                              openModal(MODAL_TYPE.EDIT_CAR);
                            }}
                          >
                            <BsFillPencilFill />
                          </Button>{" "}
                          <Button
                            variant="outline-danger"
                            onClick={() => {
                              selectCar(car);
                              openModal(MODAL_TYPE.DELETE_CAR);
                            }}
                          >
                            <BsFillTrashFill />
                          </Button>
                        </Card.Header>
                      ) : (
                        <div></div>
                      )}
                      <Card.Img
                        variant="top"
                        src={car.image}
                        style={{ maxHeight: "200px", alignSelf: "center", width: "auto"}}
                      />
                      <Card.Body>
                        <Card.Title>
                          {car.make} - {car.model}
                        </Card.Title>
                        <Card.Text>
                          年式: {car.year}年 - {car.length} mm <br />
                          {car.price?.toLocaleString()}円 
                          {/* - {car.capacity}人 */}
                        </Card.Text>
                        {currentUser && currentUser?.role === "RENTER" ? (
                          <Button
                            className="form-control submit-button"
                            onClick={() => {
                              selectCar(car);
                              openModal(MODAL_TYPE.CAR_DETAIL);
                            }}
                            disabled={!car.availability}
                          >
                            詳細
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
                <Col lg="2" className="d-flex align-items-center">
                  <Button size="lg" className="m-auto">
                    <Link className="btn" to="/cars">
                      ＞
                    </Link>
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        ))}
      </Container>
    </>
  );
};

export default Home;
