import React from "react";

const Footer = () => (
  <footer
    className="page-footer font-small blue pt-4 bg-white"
    style={{ color: "343c44" }}
  >
    <div className="container-fluid text-center text-md-left">
      <div className="row">
        <p className="text-uppercase">
          <a className="downloadLink" href="https://drive.google.com/uc?export=download&id=19zxjpSlFeSRgCr5xCftiF2tCWkOw6Kbx" download>
            会社概要 プライバシーポリシー
          </a>
        </p>
        {/* <div className="col-md-12 mt-md-0 mt-3">
          <h5 className="text-uppercase">Footer Content</h5>
          <p>
            Some message.
          </p>
        </div> */}

        <hr className="clearfix w-100 d-md-none pb-0" />

        {/* <div className="col-md-3 mb-md-0 mb-3">
          <h5 className="text-uppercase">Links</h5>
          <ul className="list-unstyled">
            <li>
              <a href="#!">Link 1</a>
            </li>
            <li>
              <a href="#!">Link 2</a>
            </li>
            <li>
              <a href="#!">Link 3</a>
            </li>
            <li>
              <a href="#!">Link 4</a>
            </li>
          </ul>
        </div> */}

        {/* <div className="col-md-3 mb-md-0 mb-3">
          <h5 className="text-uppercase">Links</h5>
          <ul className="list-unstyled">
            <li>
              <a href="#!">Link 1</a>
            </li>
            <li>
              <a href="#!">Link 2</a>
            </li>
            <li>
              <a href="#!">Link 3</a>
            </li>
            <li>
              <a href="#!">Link 4</a>
            </li>
          </ul>
        </div> */}
      </div>
    </div>

    <div className="footer-copyright text-center py-3">
      © 2023 Copyright: X-MATCH
    </div>
  </footer>
);

export default Footer;
