import React, { useEffect, useState, createContext } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { listBookings, listCars } from "../graphql-freezed/queries";
import { deleteCar } from "../graphql-freezed/mutations";
import { processBooking } from "../graphql-freezed/mutations";

const CarContext = createContext({
  featured: [],
  car: {},
  cars: [],
  bookings: [],
  loading: false,
  selectCar: () => {},
  checkout: () => {},
  removeCar: () => {},
  fetchCars: () => {},
  fetchBookings: () => {},
});

const CarProvider = ({ children }) => {
  const [cars, setCars] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [car, setCar] = useState({});
  const [featured, setFeatured] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCars();
  }, []);

  const checkout = async (orderDetails) => {
    const payload = {
      id: uuidv4(),
      ...orderDetails,
    };
    try {
      await API.graphql(graphqlOperation(processBooking, { input: payload }));
      alert("Booking is successful");
      return true;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCars = async () => {
    try {
      setLoading(true);
      // Switch authMode to API_KEY for public access
      const variables = {
        filter: {
          or: { _deleted: { ne: true } },
        },
      };
      const { data } = await API.graphql({
        query: listCars,
        variables: variables,
        authMode: "API_KEY",
      });
      const allCars = data.listCars.items;
      await Promise.all(
        allCars.map(async (car) => {
          if (car.image) {
            const url = await Storage.get(car.image);
            car.key = car.image;
            car.image = url;
          }
          return car;
        })
      );

      // Create a hashmap to categorize cars
      const categorizedCars = {
        KITCHENCAR: [],
        CAMPINGCAR: [],
        EVENTCAR: [],
      };

      allCars.forEach((car) => {
        if (car.category === "EVENTCAR") {
          categorizedCars.EVENTCAR.push(car);
        } else if (car.category === "KITCHENCAR") {
          categorizedCars.KITCHENCAR.push(car);
        } else if (car.category === "CAMPINGCAR") {
          categorizedCars.CAMPINGCAR.push(car);
        }
      });

      setCars(allCars);
      setFeatured(categorizedCars);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBookings = async (user_id) => {
    try {
      setLoading(true);
      // Switch authMode to API_KEY for public access
      const variables = {
        input: { user_id: user_id },
        filter: {
          or: [{ _deleted: { eq: false } }, { _deleted: { ne: true } }],
        },
      };
      const { data } = await API.graphql({
        query: listBookings,
        variables: variables,
        authMode: "API_KEY",
      });
      const bookings = data.listBookings.items;
      await Promise.all(
        bookings.map(async (booking) => {
          const car = booking.car;
          if (car?.image) {
            const url = await Storage.get(car.image);
            car.key = car.image;
            car.image = url;
          }
          return car;
        })
      );
      setBookings(bookings);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const selectCar = (car) => {
    const {
      id,
      make,
      model,
      year,
      capacity,
      length,
      image,
      key,
      price,
      featured,
      _version,
      availability,
    } = car;
    setCar({
      id,
      make,
      model,
      year,
      capacity,
      length,
      image,
      key,
      featured,
      price,
      _version,
      availability,
    });
  };

  const removeCar = async (id, _version) => {
    const newCars = cars.filter((car) => car.id !== id);
    setCars(newCars);
    try {
      await API.graphql({
        query: deleteCar,
        variables: { input: { id: id, _version: _version } },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <CarContext.Provider
      value={{
        car,
        cars,
        bookings,
        featured,
        loading,
        checkout,
        selectCar,
        removeCar,
        fetchCars,
        fetchBookings,
      }}
    >
      {children}
    </CarContext.Provider>
  );
};

export { CarContext, CarProvider };
