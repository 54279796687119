import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Amplify } from "aws-amplify";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { Authenticator } from "@aws-amplify/ui-react";

import { CarProvider } from "./context/cars";
import { UserProvider } from "./context/users";
import { ModalProvider } from "./context/modals";

import config from "./aws-exports";
Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Authenticator.Provider>
    <UserProvider>
      <CarProvider>
        <ModalProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </ModalProvider>
      </CarProvider>
    </UserProvider>
  </Authenticator.Provider>
);
