// ModalContext.js
import React, { createContext, useContext, useState } from "react";
import { CarContext } from "./cars";

const ModalContext = createContext();

const MODAL_TYPE = {
  NONE: 0,
  CREATE_CAR: 1,
  EDIT_CAR: 2,
  DELETE_CAR: 3,
  CAR_DETAIL: 4,
  CREATE_BOOKING: 5,
  DELETE_BOOKING: 6,
  CHECKOUT: 7,
  ERROR: 8,
  SIGNIN: 9,
};

const ModalProvider = ({ children }) => {
  const { selectCar } = useContext(CarContext);
  const [modalType, setModalType] = useState(null);

  const openModal = (type) => {
    setModalType(type);
  };

  const closeModal = () => {
    selectCar({});
    setModalType(MODAL_TYPE.NONE);
  };

  return (
    <ModalContext.Provider
      value={{
        modalType,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalProvider, ModalContext, MODAL_TYPE };
