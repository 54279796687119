import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
// Pages
import Home from "./pages/Home";
import Error from "./modals/Error";
import Cars from "./pages/Cars";
import Dashboard from "./pages/Dashboard";

// Modals
import Checkout from "./modals/Checkout";
import Admin from "./modals/Admin";
import DeleteCar from "./modals/DeleteCar";
import EditCar from "./modals/EditCar";
import CarDetails from "./modals/CarDetails";
import Signin from "./modals/Signin";

import "./index.css";

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";

import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<Cars />} path="/cars" />
          <Route element={<Dashboard />} path="/dashboard" />
          <Route element={<Error />} path="*" />
        </Routes>
        <Checkout />
        <Footer />
      </Router>
      <Signin />
      <Admin />
      <CarDetails />
      <EditCar />
      <DeleteCar />
      <Error />
    </>
  );
};

export default App;
