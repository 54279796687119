import React, { useContext } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckoutForm";
import { MODAL_TYPE, ModalContext } from "../context/modals";
import { Modal } from "react-bootstrap";

const Checkout = () => {
  const { modalType, closeModal } = useContext(ModalContext);
  const stripePromise = loadStripe(
    "pk_test_51HWGtXIW675bT8QM1i5Sk4c4NOFqSsMgE010t90zgC37DDcOKRDs6RzPSFvtD1ZnV5hio5UOpIvOmCt4BCPwoYxL00IDV1t2EC"
  );

  return (
    <Modal
      style={{ backgroundColor: "transparent" }}
      show={modalType === MODAL_TYPE.CREATE_BOOKING}
      onHide={closeModal}
    >
      <Authenticator>
        <section className="checkout-wrapper py-5">
          <Elements stripe={stripePromise}>
            <h2 className="card-title mb-4">決済を進めてよろしいですか？</h2>
            <CheckoutForm />
          </Elements>
        </section>
      </Authenticator>
    </Modal>
  );
};

export default Checkout;
