import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { createCar } from "../graphql-freezed/mutations";
import {
  Modal,
  Button,
  Form,
  Image,
} from "react-bootstrap";
import { MODAL_TYPE, ModalContext } from "../context/modals";
import { UserContext } from "../context/users";
import { CarContext } from "../context/cars";

const Admin = () => {
  const [image, setImage] = useState(null);
  const maxFileSize = 5 * 1024 * 1024; // 5 MB
  const { currentUser } = useContext(UserContext);
  const { fetchCars } = useContext(CarContext);

  const [carDetails, setCarDetails] = useState({
    make: "",
    model: "",
    year: 0,
    price: 0,
    capacity: 0,
    length: 0,
    image: "",
  });
  const { modalType, closeModal } = useContext(ModalContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        !carDetails.make ||
        !carDetails.model ||
        !carDetails.year ||
        !carDetails.price ||
        !carDetails.capacity ||
        !carDetails.length ||
        !carDetails.image ||
        !carDetails.category||
        !carDetails.featured
      )
        return;
      carDetails.user_id = currentUser.id;
      carDetails.availability = true;
      await API.graphql(graphqlOperation(createCar, { input: carDetails }));
      setCarDetails({
        make: "",
        model: "",
        year: 0,
        price: 0,
        capacity: 0,
        length: 0,
        image: "",
        category: null,
        featured: null,
      });
      setImage(null);
      fetchCars();
      closeModal();
    } catch (err) {
      console.log("error creating car:", err);
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    // Define an array of allowed mime types
    const allowedMimeTypes = ["image/png"]; // Add more types if needed

    // Check if the file type is in the list of allowed types
    if (!allowedMimeTypes.includes(file.type)) {
      alert("We only allow png now!!!");
      e.target.value = "";
      return; // Do not process the file if it has an invalid type
    }

    if (file.size > maxFileSize) {
      alert("Max File Size: 10kb");
      e.target.value = "";
      return; // do not process the file if it exceeds the size limit
    }
    const key = uuidv4();
    try {
      // Upload the file to s3 with public access level.
      await Storage.put(key, file, {
        level: "public",
        contentType: file.type,
      });
      // Retrieve the uploaded file to display
      const image = await Storage.get(key, { level: "public" });
      setImage(image);
      setCarDetails({ ...carDetails, image: key });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      show={modalType === MODAL_TYPE.CREATE_CAR}
      onHide={closeModal}
      style={{ backgroundColor: "transparent" }}
    >
      <Authenticator>
        {({ signOut }) => (
          <div>
            <Modal.Header closeButton>
              <Modal.Title>新車</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {image ? (
                  <Image
                    className="image-preview w-50"
                    src={image}
                    roundedCircle
                  />
                ) : (
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>画像アップロード</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => handleImageUpload(e)}
                    />
                  </Form.Group>
                )}

                <Form.Group className="mb-3">
                  <Form.Label>車種</Form.Label>
                  <Form.Control
                    placeholder="Type the make of the car"
                    autoFocus
                    onChange={(e) =>
                      setCarDetails({
                        ...carDetails,
                        make: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>車のモデル</Form.Label>
                  <Form.Control
                    placeholder="Type the model of the car"
                    autoFocus
                    onChange={(e) =>
                      setCarDetails({
                        ...carDetails,
                        model: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>製造年</Form.Label>
                  <Form.Control
                    placeholder="Type the year of car"
                    autoFocus
                    onChange={(e) =>
                      setCarDetails({ ...carDetails, year: e.target.value })
                    }
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>値段</Form.Label>
                  <Form.Control
                    placeholder="What is the Price of the car (Yen)"
                    autoFocus
                    onChange={(e) =>
                      setCarDetails({ ...carDetails, price: e.target.value })
                    }
                    required
                  />
                </Form.Group>

                {/* <Form.Group className="mb-3">
                  <Form.Label>容積</Form.Label>
                  <Form.Control
                    placeholder="What is the capacity of the car"
                    autoFocus
                    onChange={(e) =>
                      setCarDetails({
                        ...carDetails,
                        capacity: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group> */}

                <Form.Group className="mb-3">
                  <Form.Label>長さ</Form.Label>
                  <Form.Control
                    placeholder="What is the length of the car (mm)"
                    autoFocus
                    onChange={(e) =>
                      setCarDetails({ ...carDetails, length: e.target.value })
                    }
                    required
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>カテゴリを選んで</Form.Label>
                  <Form.Select aria-label="カテゴリを選んで" onChange={(e) =>
                      setCarDetails({ ...carDetails, category: e.target.value })
                    }>
                    <option>---</option>
                    <option value="EVENTCAR">イベントカー</option>
                    <option value="KITCHENCAR">キッチンカー</option>
                    <option value="CAMPINGCAR">キャンピングカー</option>
                  </Form.Select>
                </Form.Group>

                <Form.Check
                  type="checkbox"
                  id={carDetails.featured}
                  onChange={(e) => {
                    setCarDetails({
                      ...carDetails,
                      featured: e.target.checked,
                    });
                  }}
                  label="注目の車?"
                />
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button className="submit-buttom" onClick={handleSubmit}>
                保存
              </Button>
            </Modal.Footer>
          </div>
        )}
      </Authenticator>
    </Modal>
  );
};

export default Admin;
