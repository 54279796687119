import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";

import { MODAL_TYPE, ModalContext } from "../context/modals";

const Error = () => {
  const { modalType, closeModal } = useContext(ModalContext);

  return (
    <>
      <Modal show={modalType === MODAL_TYPE.ERROR} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>エラー!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>おっと。エラーページ</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Error;


