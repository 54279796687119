import React, { useContext, useEffect } from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { UserContext } from "../context/users";
import { CarContext } from "../context/cars";

function Dashboard() {
  const { currentUser } = useContext(UserContext);
  const { cars, bookings, fetchBookings } = useContext(CarContext);

  useEffect(() => {
    if (currentUser != null) {
      /* eslint-disable react-hooks/exhaustive-deps */
      fetchBookings(currentUser.id);
    }
  }, [currentUser]);

  console.log(bookings);

  return (
    <Container fluid className="panel">
      <Row>
        <Col md={3} className="p-4">
          <Card>
            <Card.Body>
              <Card.Title>ユーザー情報</Card.Title>
              <Card.Text>
                <strong>名前:</strong> {currentUser?.first_name}{" "}
                {currentUser?.last_name}
                <br />
                <strong>メール:</strong> {currentUser?.email}
                <br />
                <strong>住所:</strong> {currentUser?.prefecture}
                <br />
                <strong>区分:</strong>{" "}
                {currentUser?.role === "RENTER" ? (
                  "借りる"
                ) : currentUser?.role === "RENTEE" ? (
                  <p>Rentee</p>
                ) : (
                  ""
                )}
                <br />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {currentUser?.role === "RENTEE" && (
          <Col md={9} className="p-4">
            <h3>所有車一覧</h3>
            {cars.map((car) => (
              <Card style={{ marginBottom: "25px" }}>
                <Card.Body>
                  <Card.Text>
                    <Image
                      src={car.image}
                      roundedCircle
                      style={{ maxHeight: "100px" }}
                    />{" "}
                    {car.make} - {car.model}
                    {car?.bookings?.items.length > 0 && (
                      <div>
                        <h4>ブッキング</h4>
                        <ul>
                          {car?.bookings?.items.map((booking) => (
                            <li>
                              {booking?.booking?.user} -{" "}
                              {booking?.booking?.startDate} -{" "}
                              {booking?.booking?.endDate}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </Col>
        )}
        {currentUser?.role === "RENTER" && (
          <Col md={9} className="p-4">
            <Card>
              <Card.Body>
                <Card.Title>予約一覧</Card.Title>
                <Card.Text>
                  <ul>
                    {bookings.map((booking) => (
                      <li>
                        <Image
                          src={booking.car?.image}
                          roundedCircle
                          style={{ maxHeight: "100px" }}
                        />{" "}
                        {booking.car?.make} - {booking.car?.model}
                        {new Date(booking?.startDate).toLocaleDateString(
                          undefined,
                          { year: "numeric", month: "long", day: "numeric" }
                        )}{" "}
                        -{" "}
                        {new Date(booking?.endDate).toLocaleDateString(
                          undefined,
                          { year: "numeric", month: "long", day: "numeric" }
                        )}{" "}
                        -{" "}
                        {parseFloat(booking?.total).toLocaleString("en-US", {
                          style: "currency",
                          currency: "JPY",
                          maximumFractionDigits: 2,
                        })}
                      </li>
                    ))}
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default Dashboard;
