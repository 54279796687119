import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { API, Storage } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { updateCar } from "../graphql-freezed/mutations";
import { Modal, Button, Form, Image } from "react-bootstrap";
import { MODAL_TYPE, ModalContext } from "../context/modals";
import { CarContext } from "../context/cars";

const EditCar = () => {
  const { car: currentCar, fetchCars } = useContext(CarContext);
  const { modalType, closeModal } = useContext(ModalContext);

  const [image, setImage] = useState(null);
  const [carDetails, setCarDetails] = useState({
    make: "",
    model: "",
    year: 0,
    price: 0,
    capacity: 0,
    length: 0,
    image: null,
    featured: null,
    _version: 0,
    availability: null,
  });

  useEffect(() => {
    console.log(carDetails);
  }, [carDetails]);

  const handleClose = async (e) => {
    setCarDetails({});
    setImage(null);
    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      carDetails.make = carDetails.make || currentCar.make;
      carDetails.model = carDetails.model || currentCar.model;
      carDetails.year = carDetails.year || currentCar.year;
      carDetails.price = carDetails.price || currentCar.price;
      carDetails.length = carDetails.length || currentCar.length;
      carDetails.capacity = carDetails.capacity || currentCar.capacity;
      carDetails.image = carDetails.image || currentCar.key;
      carDetails.featured = carDetails.featured !== null
        ? carDetails.featured
        : currentCar.featured;
      carDetails._version = carDetails._version || currentCar._version;
      carDetails.availability = carDetails.availability !== null
        ? carDetails.availability
        : currentCar.availability;
      if (
        !carDetails.make ||
        !carDetails.model ||
        !carDetails.year ||
        !carDetails.price ||
        !carDetails.capacity ||
        !carDetails.length ||
        !carDetails.image ||
        carDetails.featured === null ||
        !carDetails._version ||
        carDetails.availability === null
      )
        return;
      const payload = { id: currentCar.id, ...carDetails };
      await API.graphql({
        query: updateCar,
        variables: {
          input: payload,
        },
      });
      fetchCars();
      setCarDetails({
        make: "",
        model: "",
        year: 0,
        price: 0,
        capacity: 0,
        length: 0,
        image: "",
        featured: false,
        availability: true,
      });
      closeModal();
    } catch (err) {
      console.log("error updating car:", err);
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const key = uuidv4();
    try {
      // Upload the file to s3 with public access level.
      await Storage.put(key, file, {
        level: "public",
        contentType: file.type,
      });
      // Retrieve the uploaded file to display
      const image = await Storage.get(key, { level: "public" });
      setImage(image);
      setCarDetails({ ...carDetails, image: key });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      show={modalType === MODAL_TYPE.EDIT_CAR}
      onHide={handleClose}
      style={{ backgroundColor: "transparent" }}
    >
      <Authenticator>
        {({ signOut }) => (
          <div>
            <Modal.Header closeButton>
              <Modal.Title>新車</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Image
                  className="image-preview w-50"
                  src={image ? image : currentCar.image}
                  roundedCircle
                />

                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>画像アップロード</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => handleImageUpload(e)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>車種</Form.Label>
                  <Form.Control
                    placeholder="Type the make of the car"
                    autoFocus
                    value={carDetails.make ? carDetails.make : currentCar.make}
                    onChange={(e) =>
                      setCarDetails({
                        ...carDetails,
                        make: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>車のモデル</Form.Label>
                  <Form.Control
                    placeholder="Type the model of the car"
                    autoFocus
                    value={
                      carDetails.model ? carDetails.model : currentCar.model
                    }
                    onChange={(e) =>
                      setCarDetails({
                        ...carDetails,
                        model: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>製造年</Form.Label>
                  <Form.Control
                    placeholder="Type the year of car"
                    autoFocus
                    value={carDetails.year ? carDetails.year : currentCar.year}
                    onChange={(e) =>
                      setCarDetails({ ...carDetails, year: e.target.value })
                    }
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>値段</Form.Label>
                  <Form.Control
                    placeholder="What is the Price of the car (Yen)"
                    autoFocus
                    value={
                      carDetails.price ? carDetails.price?.toLocaleString() : currentCar.price?.toLocaleString()
                    }
                    onChange={(e) =>
                      setCarDetails({ ...carDetails, price: e.target.value })
                    }
                    required
                  />
                </Form.Group>

                {/* <Form.Group className="mb-3">
                  <Form.Label>容積</Form.Label>
                  <Form.Control
                    placeholder="What is the capacity of the car"
                    autoFocus
                    value={
                      carDetails.capacity
                        ? carDetails.capacity
                        : currentCar.capacity
                    }
                    onChange={(e) =>
                      setCarDetails({
                        ...carDetails,
                        capacity: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group> */}

                <Form.Group className="mb-3">
                  <Form.Label>長さ</Form.Label>
                  <Form.Control
                    placeholder="What is the length of the car (mm)"
                    autoFocus
                    value={
                      carDetails.length ? carDetails.length : currentCar.length
                    }
                    onChange={(e) =>
                      setCarDetails({ ...carDetails, length: e.target.value })
                    }
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    defaultChecked={currentCar.featured}
                    checked={carDetails.featured}
                    onChange={(e) => {
                      setCarDetails({
                        ...carDetails,
                        featured: e.target.checked,
                      });
                    }}
                    id="featured"
                    label="Featured?"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Available for rent?</Form.Label>

                  <Form.Check
                    type="switch"
                    id="availability"
                    defaultChecked={currentCar.availability}
                    checked={carDetails.availability}
                    onChange={(e) => {
                      setCarDetails({
                        ...carDetails,
                        availability: e.target.checked,
                      });
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button className="submit-buttom" onClick={handleSubmit}>
                保存
              </Button>
            </Modal.Footer>
          </div>
        )}
      </Authenticator>
    </Modal>
  );
};

export default EditCar;
