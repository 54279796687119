import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CarContext } from "../context/cars";
import { API } from "aws-amplify";
import { ModalContext } from "../context/modals";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { UserContext } from "../context/users";
import { updateCar } from "../graphql-freezed/mutations";
import { Form } from "react-bootstrap";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CheckoutForm = () => {
  const { car, checkout, fetchCars } = useContext(CarContext);
  const { currentUser } = useContext(UserContext);
  const { closeModal } = useContext(ModalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [numDays, setNumDays] = useState(1); // Default to 1 day

  const dailyRate = car.price;

  const [orderDetails, setOrderDetails] = useState({
    user_id: currentUser.id,
    car_id: car.id,
    price: car.price,
    token: null,
    startDate: startDate,
    endDate: endDate,
  });

  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleChangeStartDate = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    const formattedStartDate = formatDate(newStartDate);
    setOrderDetails((prevOrderDetails) => ({
      ...prevOrderDetails,
      startDate: formattedStartDate,
    }));
  };
  
  const handleChangeEndDate = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    const formattedEndDate = formatDate(newEndDate);
    setOrderDetails((prevOrderDetails) => ({
      ...prevOrderDetails,
      endDate: formattedEndDate,
    }));
  };
  
  // Function to format a date as 'YYYY-MM-DDTHH:MM:SSZ'
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  }

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const daysDifference = (end - start) / (1000 * 60 * 60 * 24) + 1;
      setNumDays(Math.max(1, daysDifference)); // Ensure at least 1 day

      if (start > end) {
        setError("開始日は終了日よりも前である必要があります。");
        setIsLoading(true);
        setIsError(true);
      } else {
        setError(""); // Clear any previous error message
        setIsLoading(false);
        setIsError(false);
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (orderDetails.token) {
      setIsLoading(true);
      checkout(orderDetails)
        .then(async (result) => {
          if (result) {
            const { key, availability, ...dataWithoutKey } = car;
            const modifiedData = {
              ...dataWithoutKey,
              image: car.key,
              _version: car._version,
            };
            const payload = { availability: false, ...modifiedData };
            await API.graphql({
              query: updateCar,
              variables: {
                input: payload,
              },
            });
            fetchCars();
            closeModal();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [orderDetails]);

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    console.log(orderDetails)
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      const token = result.token;
      setOrderDetails({
        ...orderDetails,
        email: currentUser.email,
        token: token.id,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="checkout-form">
        <div className="stripe-section" style={{ marginBottom: "10px" }}>
          <label htmlFor="stripe-element">
            クレジットカードまたはデビットカード
          </label>
          <CardElement
            id="stripe-element"
            options={CARD_ELEMENT_OPTIONS}
            onChange={handleChange}
          />
        </div>
        <div className="date-pickers">
          <Form.Group controlId="startDate">
            <Form.Label>開始日</Form.Label>
            <Form.Control
              type="date"
              name="startDate"
              value={startDate}
              onChange={handleChangeStartDate}
              placeholder="開始日"
            />
          </Form.Group>
          <Form.Group controlId="endDate">
            <Form.Label>終了日</Form.Label>
            <Form.Control
              type="date"
              name="endDate"
              value={endDate}
              onChange={handleChangeEndDate}
              placeholder="終了日"
            />
          </Form.Group>
        </div>
        <div className="price-info">
          <p>1日あたりの料金: {dailyRate?.toLocaleString()}円</p>
          <p>合計金額: {(numDays * dailyRate)?.toLocaleString()}円</p>
        </div>
        <div className="card-errors" role="alert">
          {error}
        </div>
      </div>
      <button type="submit" className="btn" disabled={isLoading | isError}>
        {isLoading ? (
          isError ? (
            <span>エラー </span>
          ) : (
            <span>申し出る。。。</span>
          )
        ) : (
          "決済する"
        )}
      </button>
    </form>
  );
};

export default CheckoutForm;
